declare global {
  interface Window {
    APP_API_URL: string;
    APP_ENVIRONMENT: string;
    APP_SENTRY_DSN: string;
    APP_RECAPTCHA_API_KEY: string;
  }
}

export const API_URL = process.env.REACT_APP_API_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const RECAPTCHA_API_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const TYPEFORM_LINK = process.env.REACT_APP_TYPEFORM_LINK;
export const APPLICATIONS_OPEN = process.env.REACT_APP_APPLICATIONS_OPEN === "true";
