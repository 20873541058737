import Home from "pages/Home";
import Fellows from "pages/Fellows";

export const Routes = {
  home: {
    component: Home,
    path: "/",
    exact: true,
  },
  fellows: {
    component: Fellows,
    path: "/fellows",
    exact: true,
  },
};
