import styled from "styled-components";
import { useEffect, useState } from "react";

import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as LogoSmall } from "assets/icons/logo-small.svg";
import { ReactComponent as MenuClose } from "assets/icons/menu-close.svg";
import { Link, useHistory, useLocation } from "react-router-dom";

const HeaderContainer = styled.header`
  width: 100%;
  position: absolute;
  left: 0;
  top: 74px;
  height: 86px;
  z-index: 99;
  display: flex;
  align-items: center;
  .logo {
    cursor: pointer;
  }
  &.is-sticky {
    position: fixed;
    top: 0;
    z-index: 99;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .menu {
    .mobile-menu {
      display: none;
      .briefcase {
        display: block;
        font-size: 25px;
        margin-right: 20px;
      }
      .menu-btn {
        background-color: transparent;
        border: none;
        outline: none;
        padding: 5px;
      }
    }
    ul {
      margin: 0px;
      padding: 0px;
      transition: all ease 0.4s;
      li {
        font-family: "new-order", sans-serif;
        font-weight: 600;
        display: inline-block;
        margin-left: 55px;
        font-size: 14px;
        .link {
          color: ${({ theme }) => theme.colors.secondary};
          cursor: pointer;
          &:hover,
          &:focus {
            color: ${({ theme }) => theme.colors.hoverLight};
          }
        }
        &.apply-now {
          position: relative;
          .link {
            text-shadow: 2px 2px 14px #00a0d8;
            color: #3e97c7;
          }
        }
        &.icon {
          font-size: 20px;
        }
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    top: 0;

    .menu {
      .mobile-menu {
        display: flex;
        align-items: center;
      }
      ul {
        position: absolute;
        top: 86px;
        background-color: ${({ theme }) => theme.colors.primary};
        width: 100%;
        max-height: 0px;
        overflow: hidden;
        left: 0;
        transition: all ease 0.4s;
        li {
          display: block;
          border-top: 1px solid ${({ theme }) => theme.colors.borderDark};
          margin: 0;
          .link {
            display: block;
            height: 70px;
            line-height: 70px;
            padding: 0 100px;
            transition: all ease 0.2s;
            &:hover {
              background-color: ${({ theme }) => theme.colors.hoverDark};
              color: ${({ theme }) => theme.colors.secondary};
            }
          }
          &.icon {
            display: none;
          }
          &.apply-now {
            .link {
              svg {
                display: none;
              }
              span {
                position: initial;
                top: initial;
                left: initial;
                line-height: 70px;
                text-align: left;
              }
            }
          }
        }
        &.open {
          max-height: 999px;
          border-bottom: 1px solid #212121;
        }
      }
    }
  }
  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    .menu {
      ul {
        li {
          .link {
            padding: 0 25px;
          }
        }
      }
    }
  }
`;

const Header: React.FC = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [menuOpen, setMenuOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const scrollTo = (selector) => {
    if (menuOpen) setMenuOpen(false);
    document.querySelector(selector).scrollIntoView({ behavior: "smooth" });
  };

  const isSticky = () => {
    const header = document.querySelector(".header-section");
    window.scrollY >= 74 ? header.classList.add("is-sticky") : header.classList.remove("is-sticky");
  };

  const onResize = () => {
    setWidth(window.innerWidth);
  };

  const isHome = location.pathname === "/";
  const isFellows = location.pathname === "/fellows";

  useEffect(() => {
    onResize();
    isSticky();
    window.addEventListener("scroll", isSticky);
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("scroll", isSticky);
      window.removeEventListener("resize", onResize);
    };
  });

  return (
    <HeaderContainer className="header-section">
      <nav className="main-width flex-between">
        <span className="logo" onClick={() => (!isHome ? history.push("/") : scrollTo("#main"))}>
          {width > 768 ? <Logo /> : <LogoSmall />}
        </span>

        <div className="menu">
          <div className="mobile-menu">
            <a href="https://sevensevensix.com" target="_blank" className="briefcase" rel="noreferrer">
              <span>💼</span>
            </a>
            <button className="menu-btn" onClick={() => setMenuOpen((open) => !open)}>
              {menuOpen ? <MenuClose /> : <Menu />}
            </button>
          </div>

          <ul className={menuOpen ? "open" : ""}>
            <li className={isHome ? "apply-now" : ""}>
              <span className="link" onClick={() => (!isHome ? history.push("/") : scrollTo("#application"))}>
                Application
              </span>
            </li>
            {/* <li className={isFellows && "apply-now"}>
              <Link to="/fellows">
                <span className="link">Fellows</span>
              </Link>
            </li> */}
            <li>
              <span className="link" onClick={() => (!isHome ? history.push("/") : scrollTo("#letter-from-director"))}>
                Annual Letter
              </span>
            </li>
            <li>
              <span className="link" onClick={() => (!isHome ? history.push("/") : scrollTo("#faq"))}>
                FAQ
              </span>
            </li>
            <li>
              <span className="link" onClick={() => (!isHome ? history.push("/") : scrollTo("#team"))}>
                Team
              </span>
            </li>
            <li className="icon">
              <a href="https://sevensevensix.com" target="_blank" rel="noreferrer">
                💼
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </HeaderContainer>
  );
};

export default Header;
