import { observer, Provider } from "mobx-react";
import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";

import Pages from "pages/Pages";
import RootStore from "stores/RootStore";
import ThemeProvider from "theme/Theme";

const App: React.FunctionComponent<{}> = () => {
  const [store] = useState(() => new RootStore());

  return (
    <ThemeProvider>
      <BrowserRouter>
        <Provider store={store}>
          <Pages />
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default observer(App);
