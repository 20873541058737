import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { useState } from "react";
import Container from "react-bootstrap/Container";

import { processServerResponse } from "utils/httpUtils";
import { API_URL } from "config/Config";
import Radio from "./form/Radio";
import Label from "./form/Label";
import Input from "./form/Input";
import Select from "./form/Select";
import Textarea from "./form/Textarea";
import FieldError from "./form/FieldError";

export const Discovery = {
  socialMedia: "Social Media",
  press: "Press",
  friend: "Friend",
  other: "Other",
};

export const EligibilityQuiz = {
  public_charity: "501(c)(3) Public Charity",
  org_sponsored: "Non 501(c)(3) organizations fiscally sponsored by a 501(c)(3) Public Charity",
  Individual: "Individual",
};

const DiscoveryOptions = Object.values(Discovery).map((value) => ({
  id: value,
  text: value,
}));

const EligibilityQuizOptions = Object.values(EligibilityQuiz).map((value) => ({
  id: value,
  text: value,
}));

const FormSection = styled.section`
  width: 100%;
  position: relative;
  z-index: 9;
  padding: 100px 0;
  background-color: ${({ theme }) => theme.colors.secondary};
  .heading {
    text-align: center;
    p {
      font-family: Neue-Haas, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #393939;
      opacity: 0.8;
      margin-bottom: 45px;
    }
  }
  form {
    width: 710px;
    max-width: 100%;
    margin: 0 auto;
    .button-wrapper {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
    }

    select {
      color: #393939;
      background-color: #fff;
      -webkit-appearance: caret;
    }

    input[type="date"] {
      -webkit-appearance: caret;
      background-color: #fff;
      padding: 14px;
      color: #393939;
      min-height: 50px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
    form {
      .button-wrapper {
        .main-button {
          width: 100%;
        }
      }
    }
  }
`;

const Form: React.FC = () => {
  const [fullName, setFullName] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [twitter, setTwitter] = useState<string>("");
  const [instagram, setInstagram] = useState<string>("");
  const [tikTok, setTikTok] = useState<string>("");
  const [linkedIn, setLinkedIn] = useState<string>("");
  const [university, setUniversity] = useState<string>(null);
  const [underrepresented, setUnderrepresented] = useState<string>(null);
  const [identityDescription, setIdentityDescription] = useState<string>("");
  const [fixPlanetDescription, setFixPlanetDescription] = useState<string>("");
  const [experienceDescription, setExperienceDescription] = useState<string>("");
  const [climateChangeDescription, setClimateChangeDescription] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [youtube, setYoutube] = useState<string>("");
  const [discovery, setDiscovery] = useState<string>("");
  const [eligibilityQuiz, setEligibilityQuiz] = useState<string>("");
  const [errors, setErrors] = useState<{
    fullName?: string;
    dateOfBirth?: string;
    email?: string;
    location?: string;
    twitter?: string;
    instagram?: string;
    tikTok?: string;
    linkedIn?: string;
    university?: string;
    underrepresented?: string;
    identityDescription?: string;
    fixPlanetDescription?: string;
    experienceDescription?: string;
    climateChangeDescription?: string;
    website?: string;
    youtube?: string;
    discovery?: string;
    eligibilityQuiz?: string;
  }>({});
  const [saving, setSaving] = useState<boolean>(false);
  const [applied, setApplied] = useState<boolean>(false);

  const apply = async () => {
    setSaving(true);
    const form = {
      fullName,
      dateOfBirth: dateOfBirth ? moment(dateOfBirth).format("MM/DD/YYYY") : null,
      email,
      location,
      twitter,
      instagram,
      tikTok,
      linkedIn,
      university,
      underrepresented,
      identityDescription,
      fixPlanetDescription,
      experienceDescription,
      climateChangeDescription,
      website,
      youtube,
      discovery,
      eligibilityQuiz,
    };
    try {
      const response = await fetch(`${API_URL}/google/sheets`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      });
      await processServerResponse(response);
      setApplied(true);
    } catch (e) {
      if (e.meta) {
        const newErrors = {};
        Object.keys(e.meta).forEach((key) => (newErrors[key] = e.meta[key].message));
        setErrors(newErrors);
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <FormSection id="form">
      <div className="heading">
        <h1 className="standard-title black">
          {applied && (
            <>
              🎉
              <br />
              Thank you for applying
            </>
          )}
          {!applied && "Apply for the fellowship"}
        </h1>
        <p>{!applied && "Marked * fields are required."}</p>
      </div>
      {!applied && (
        <form>
          <Container>
            <Row>
              <Col xs={12} sm={6}>
                <Input
                  maxLength={50}
                  label="Full Name*"
                  value={fullName}
                  error={errors.fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  type="date"
                  label="Date of Birth*"
                  error={errors.dateOfBirth}
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Input
                  type="email"
                  maxLength={50}
                  label="Email*"
                  value={email}
                  error={errors.email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  label="Location*"
                  maxLength={50}
                  value={location}
                  error={errors.location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </Col>
            </Row>

            <Row className="mt-20 mb-20">
              <Col md={12}>
                <Label label="Are you currently in University*" />
                <div className="flex align-center">
                  <Radio
                    checked={university === "YES"}
                    label="Yes"
                    name="university"
                    value="YES"
                    className="mr-25"
                    onChange={(e) => setUniversity(e.target.value)}
                  />
                  <Radio
                    checked={university === "NO"}
                    label="No"
                    name="university"
                    value="NO"
                    onChange={(e) => setUniversity(e.target.value)}
                  />
                </div>
                {errors.university && <FieldError text={errors.university} />}
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6}>
                <Input
                  label="Twitter"
                  maxLength={100}
                  value={twitter}
                  error={errors.twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  label="Instagram"
                  maxLength={100}
                  value={instagram}
                  error={errors.instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Input label="TikTok" maxLength={100} value={tikTok} onChange={(e) => setTikTok(e.target.value)} />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  label="LinkedIn"
                  maxLength={100}
                  value={linkedIn}
                  error={errors.linkedIn}
                  onChange={(e) => setLinkedIn(e.target.value)}
                />
              </Col>
            </Row>

            <Row className="mt-20 mb-20">
              <Col md={12}>
                <Label label="🦄 Do you consider yourself underrepresented?*" />
                <div className="flex align-center">
                  <Radio
                    checked={underrepresented === "YES"}
                    label="Yes"
                    name="underrepresented"
                    value="YES"
                    className="mr-25"
                    onChange={(e) => setUnderrepresented(e.target.value)}
                  />
                  <Radio
                    checked={underrepresented === "NO"}
                    label="No"
                    name="underrepresented"
                    value="NO"
                    onChange={(e) => setUnderrepresented(e.target.value)}
                  />
                </div>
                {errors.underrepresented && <FieldError text={errors.underrepresented} />}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Textarea
                  value={identityDescription}
                  error={errors.identityDescription}
                  label="💬 What would you like us to know about your identity? This is your opportunity to proactively share anything about yourself that has helped to shape your perspective. Tell us the story of you!*"
                  infoText="The maximum number of characters is 5000."
                  maxLength={5000}
                  onChange={(e) => setIdentityDescription(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Textarea
                  value={fixPlanetDescription}
                  error={errors.fixPlanetDescription}
                  label="🌎 What was the moment in your life when you knew you wanted to do something to help fix the planet?*"
                  infoText="The maximum number of characters is 5000."
                  maxLength={5000}
                  onChange={(e) => setFixPlanetDescription(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Textarea
                  value={experienceDescription}
                  error={errors.experienceDescription}
                  label="🗣 Without sharing your resume tell us your experience and why you are dedicated to leaving school to work on this project?*"
                  infoText="The maximum number of characters is 5000."
                  maxLength={5000}
                  onChange={(e) => setExperienceDescription(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Textarea
                  value={climateChangeDescription}
                  error={errors.climateChangeDescription}
                  label="💡And finally, tell us your idea for combating climate change and how you’re actively working towards it. (please upload a private YouTube link below explaining your idea)*"
                  infoText="The maximum number of characters is 5000."
                  maxLength={5000}
                  onChange={(e) => setClimateChangeDescription(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Input
                  value={youtube}
                  error={errors.youtube}
                  label="Add Youtube video*"
                  infoText="Please upload a private YouTube link here."
                  maxLength={100}
                  onChange={(e) => setYoutube(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Input
                  label="Add website URL"
                  value={website}
                  error={errors.website}
                  maxLength={100}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Select
                  label="Tell us how you heard about the 776 Foundation and/or Fellowship Program*"
                  value={discovery}
                  error={errors.discovery}
                  options={DiscoveryOptions}
                  onChange={(e) => setDiscovery(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Select
                  label="Please select which category your project falls under*"
                  value={eligibilityQuiz}
                  error={errors.eligibilityQuiz}
                  options={EligibilityQuizOptions}
                  onChange={(e) => setEligibilityQuiz(e.target.value)}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="button-wrapper">
                  <button disabled={saving} className="main-button secondary" onClick={apply}>
                    {saving ? "Applying..." : "Apply Now"}
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      )}
    </FormSection>
  );
};

export default Form;
