import { FC } from "react";
import styled from "styled-components";
import DirectorSignature from "assets/images/director-signature.png";

const LetterFromDirectorDiv = styled.div`
  display: block;
  width: 789px;
  max-width: 100%;
  padding: 150px 20px;
  margin: 0 auto;
  color: #fff;
  padding-bottom: 100px;
  font-family: "Neue-Haas";

  h2 {
    font-size: 30px;
    line-height: 1.6;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;
    font-family: "new-order";
  }

  h4 {
    font-weight: 700;
    font-size: 19px;
    margin-bottom: 25px;
  }

  .text {
    color: #cecfd0;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 2%;
  }

  span {
    display: block;
    font-size: 12px;
    font-weight: 700;
    margin-top: 15px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    padding: 50px 20px;
  }
`;

const LetterFromDirector: FC = () => {
  return (
    <LetterFromDirectorDiv id="letter-from-director">
      <h2>A Letter from our Director</h2>

      <h4>A Year in Review:</h4>

      <div className="text">
        <p>
          The discourse on climate change is indisputably one of the most critical conversations of our time. The
          younger generation, who stand to inherit the earth and its challenges, has demonstrated an extraordinary
          commitment to engaging in this discourse. In light of this, the 776 Foundation launched the Climate Fellowship
          as an ambitious experiment: could we unite young climate tech-founders, climate activists and scientists from
          every corner of the globe in a singular, inclusive program to catalyze impactful change?
        </p>

        <p>
          A year has passed, and it's incredible to see how far the fellows have come in our first cohort, whether it's
          raising venture rounds from top tier investors or having experiments scientifically published. Looking back,
          it's evident our Climate Fellowship is something special - it's not every day you find such a unique mix of
          young scientists, startup founders, and activists all working towards one common goal. We've created a
          powerful blend of knowledge, innovation, and passion, setting a new standard for what youth-led climate action
          can look like. And that, in itself, is something pretty amazing.
        </p>

        <p>
          The inception of the 776 Foundation Climate Fellowship illuminated a previously unseen gap in the climate
          action landscape. Our initiative – giving 18 - 24 year old climate leaders $100K grants over two years to
          transform their ideas into tangible actions – was a concept that truly resonated. Once introduced, top
          philanthropists, leading climate tech investors, and most importantly, the climate leaders themselves,
          recognized the missing link it filled. Their overwhelming response and enthusiasm were both humbling and
          rewarding, underlining the fellowship's importance and potential. This demonstrated that our approach not only
          addressed a vital need, but also sparked a realization: investing in the youth’s fight against climate change
          is indeed an investment in the future of our planet.
        </p>

        <p>
          Navigating through the first year of the fellowship, we've had the privilege of witnessing first-hand the
          pioneering work of our climate tech founders. Their wide-ranging initiatives - from Roya Amini-Naieni’s
          company, Trilobio, who are revolutionizing synthetic biology through the creation of automated robotic labs,
          enhancing data quality and reproducibility to Sasankh Munukutla’s company, which is developing satellite and
          AI-based systems for gigaton scale soil carbon sequestration verification - have underscored what we already
          knew: climate tech will be a game-changer in our battle against climate change. The incredible strides they've
          made in just a year showcase the huge potential that lies in the intersection of technology and climate
          action. This fellowship is not just a platform for these young entrepreneurs to flourish, but also a testament
          to the powerful role of climate tech in shaping our sustainable future. As we move into the second year, we
          look forward to continuing to spotlight their achievements and impressive work.
        </p>

        <p>
          As for the youth climate activists, their passionate advocacy brought the human element to the fore, reminding
          us that climate change isn't just a scientific or economic problem - it's a humanitarian crisis. Their
          powerful narratives and relentless spirit inspired their peers, reinforcing the importance of grassroots
          activism in driving policy changes and community engagement. Their inclusion in our fellowship underscored the
          importance of coupling scientific innovation and entrepreneurship with the heart and voice of activism.
        </p>

        <p>
          Our first year has been a journey of discovery, of learning, and most importantly, of hope. It has affirmed
          our belief that young people, given the right platform and resources, can indeed lead the way in the fight
          against climate change. The 776 Foundation Climate Fellowship, therefore, stands as a testament to the power
          of youthful energy and the potential for global collaboration in the face of our planet's most critical
          challenge.
        </p>

        <p>Empowering Today's Youth for Tomorrow's World</p>
      </div>

      <img src={DirectorSignature} alt="Fellowship Director's signature" />

      <span>Lissie Garvin, 776 Foundation & Fellowship Director</span>
    </LetterFromDirectorDiv>
  );
};

export default LetterFromDirector;
