import styled from "styled-components";
import {
  Banner,
  Footer,
  Header,
  FAQ,
  Qualifications,
  SelectionProcess,
  Goals,
  Team,
  ProgramMission,
  ApplicationClosed,
  Application,
} from "../components";
import Background from "assets/images/bg-desktop.jpg";
import MobileBackground from "assets/images/bg-mobile.jpg";
import MailingListSection from "components/MailingListSection";
import LetterFromDirector from "components/LetterFromDirector";
import { APPLICATIONS_OPEN } from "config/Config";
import { useEffect } from "react";

const Wrapper = styled.div<{ background: { mobile: string; desktop: string } }>`
  background-position: top center;
  background-image: url(${({ background }) => background.desktop});
  background-repeat: no-repeat;

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    background-image: url(${({ background }) => background.mobile});
  }
`;

const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Wrapper className="w-full" background={{ mobile: MobileBackground, desktop: Background }}>
        <Banner />
        <Goals />
        <ProgramMission />
        <SelectionProcess />
        <Qualifications />
        <FAQ />
      </Wrapper>
      <Team />
      <LetterFromDirector />
      {!APPLICATIONS_OPEN && (
        <>
          <MailingListSection />
        </>
      )}
      <ApplicationClosed />
      {APPLICATIONS_OPEN && <Application />}
      <Footer />
    </>
  );
};

export default Home;
