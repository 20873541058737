import styled from "styled-components";
import TeamMember from "./TeamMember";
import members from "./teamMembers";
import backgroundShadow from "assets/images/shadow.png";

const TeamSectionWrapper = styled.div<{ backgroundImage: string }>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  padding: 100px 0 40px;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: -100px -150px;
  background-repeat: no-repeat;

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    padding: 40px 0 80px;
    background-position: -450px -100px;
  }
`;

const TeamSectionTitle = styled.div`
  max-width: 750px;
  margin: 50px 0;
  text-align: center;

  h5 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 30px;
    line-height: 64px;
    margin-bottom: 11px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    margin: 0 0 20px;

    h5 {
      margin-bottom: 0;
      width: 100%;
      font-size: 20px;
    }
  }
`;

const TeamSectionContent = styled.div`
  display: flex;
  z-index: 2;

  & > div:first-child {
    margin-right: 70px;
  }

  & > div:last-child {
    margin-left: 70px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    flex-direction: column;

    & > div:first-child {
      margin-right: 0;
      margin-bottom: 40px;
    }

    & > div:last-child {
      margin-left: 0;
    }
  }
`;

const Team: React.FC = () => {
  return (
    <TeamSectionWrapper id="team" backgroundImage={backgroundShadow}>
      <TeamSectionTitle>
        <h5>Team</h5>
      </TeamSectionTitle>
      <TeamSectionContent>
        {members.map((member) => (
          <TeamMember key={member.name} member={member} />
        ))}
      </TeamSectionContent>
    </TeamSectionWrapper>
  );
};

export default Team;
