import clsx from "clsx";

import FormItem from "./FormItem";

const Input: React.FC<{
  value: string | number;
  error?: string;
  type?: "text" | "date" | "number" | "email" | "password";
  variant?: "default" | "outline";
  prefix?: string;
  label?: string;
  infoText?: string;
  maxLength?: number;
  errorColor?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  value,
  label,
  error,
  variant = "default",
  prefix,
  type = "text",
  maxLength,
  errorColor,
  infoText,
  onChange = () => {},
}) => {
  return (
    <FormItem variant={variant} label={label} error={error} errorColor={errorColor} infoText={infoText}>
      <div className="input-wrapper">
        <span className="prefix">{prefix}</span>
        <input
          type={type}
          value={value}
          className={clsx("form-input", variant, !!prefix && "with-prefix", error ? "error" : "")}
          maxLength={maxLength}
          onChange={onChange}
        />
      </div>
    </FormItem>
  );
};

export default Input;
