import styled from "styled-components";

const LabelWrapper = styled.label`
  color: #393939;
  font-size: 13px;
  font-family: Neue-Haas, sans-serif;
`;

const WhiteLabelWrapper = styled.label`
  color: #fff;
  font-size: 13px;
  font-family: Neue-Haas, sans-serif;
`;

const Label: React.FC<{
  label?: string;
  variant?: "default" | "outline";
}> = ({ label, variant }) => {
  if (!label) return null;

  const Component = variant === "outline" ? WhiteLabelWrapper : LabelWrapper;

  return <Component>{label}</Component>;
};

export default Label;
