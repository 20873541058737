import styled from "styled-components";

const QualificationSectionWrapper = styled.div`
  padding: 230px 0 130px;
  color: #fff;

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    padding: 0;
  }
`;

const Title = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 70px;

  h5 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 30px;
    line-height: 64px;
  }

  span {
    text-align: left;
    font-family: "Neue-Haas";
    font-size: 16px;
    color: #959595;
    max-width: 600px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    padding: 0 40px 0 20px;
    align-items: flex-start;

    h5 {
      font-size: 20px;
      line-height: 33px;
      text-align: left;
    }

    span {
      font-size: 14px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    flex-direction: column;
  }
`;

const ContentItem = styled.div`
  max-width: 370px;
  box-sizing: border-box;

  h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 64px;

    .danger {
      color: ${({ theme }) => theme.colors.neutralDanger};
      text-transform: uppercase;
    }

    .success {
      color: ${({ theme }) => theme.colors.success};
      text-transform: uppercase;
    }
  }

  p {
    font-family: "Neue-Haas";
    margin-right: 48px;
    color: #cecfd0;
  }

  div {
    margin-bottom: 20px;
    display: flex;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    max-width: 100%;
    padding: 0 40px 0 20px;

    h5 {
      font-size: 18px;
    }

    p {
      margin-right: 0;
      font-size: 14px;
    }
  }
`;

const Qualifications: React.FC = () => {
  return (
    <QualificationSectionWrapper>
      <Title>
        <h5>Fellow Qualifications</h5>
        <span>
          We're searching for the builders, the doers — people motivated by problems and using that energy to create
          real, measurable solutions that will benefit underserved communities. We want our fellows to wake up every
          morning feeling excited and inspired by the project they’re working on.
        </span>
      </Title>
      <Content>
        <ContentItem>
          <h5>
            This fellowship <span className="success">IS</span>:
          </h5>
          <div>
            <span className="me-2">✅</span>
            <p>
              Skipping or dropping out of university to focus exclusively on a project, idea, or initiative full-time
              that is working toward solving the climate change crisis.
            </p>
          </div>
          <div>
            <span className="me-2">✅</span>
            <p> Fellows must be ages 18 to 24 and can live anywhere in the world.</p>
          </div>
        </ContentItem>
        <ContentItem>
          <h5>
            This fellowship <span className="danger">IS NOT</span>:
          </h5>
          <div>
            <span className="me-2">❌</span>
            <p>A paid vacation</p>
          </div>
          <div>
            <span className="me-2">❌</span>
            <p>A networking program; your job as a fellow is to ship</p>
          </div>
        </ContentItem>
      </Content>
    </QualificationSectionWrapper>
  );
};

export default Qualifications;
